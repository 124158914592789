.tryFree {
    padding: 40px 0;
    text-align: center;
    h2 {
        margin-bottom: 24px;
    }
    header {
        margin-bottom: 30px;
    }
}
