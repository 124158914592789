.header {
    box-sizing: border-box;
    margin: 0 0 60px;
    h1,
    h2 {
        color: #000;
        font-family: var(--rubik);
        font-weight: 600;
        margin: 0 0 30px;
        padding: 0;
        line-height: 1.3;
        @media only screen and (min-width: 768px) {
            line-height: 1.35;
        }
    }
    h1 {
        font-size: 28px;
        @media only screen and (min-width: 768px) {
            font-size: 54px;
        }
    }
    h2 {
        font-size: 28px;
        @media only screen and (min-width: 768px) {
            font-size: 34px;
        }
        span {
            color: var(--primary-brand-color-600);
        }
    }
    p {
        color: #000;
        text-align: center;
        font-family: var(--rubik);
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        margin: 0 0 20px;
        padding: 0;
        b,
        strong {
            font-weight: 700;
        }
    }
}
