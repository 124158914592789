/* stylelint-disable at-rule-no-unknown */
.dialogContainer[open] {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    margin: 0;
    padding: 0;
    border: 0;
    pointer-events: all;
}
.dialog {
    background: #fff url('./assets/bg.svg') left center no-repeat;
    background-size: 560px 219px;
    box-shadow: 20px 20px 90px 0 rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    width: 100%;
    max-width: 600px;
    min-height: 292px;
    margin: 20px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    display: flex;
}
.inner {
    align-self: center;
    width: 100%;
    padding: 30px 20px;
    h2 {
        color: #000;
        position: relative;
        z-index: 1;
        font-size: 30px;
        font-weight: 600;
        line-height: 1.35;
        font-family: var(--rubik);
        margin: 0 0 24px;
        --underline-offset: 0.1em;
        u {
            color: var(--primary-brand-color-600);
        }
        @media screen and (min-width: 768px) {
            font-size: 34px;
        }
    }
    p {
        color: #000;
        font-family: var(--rubik);
        font-size: 16px;
        font-weight: 700;
        line-height: 1.2;
        margin: 0 0 17px;
    }
}
.close {
    position: absolute;
    padding: 10px;
    top: 0;
    right: 0;
    display: inline-flex;
    background: none;
    border: none;
    color: #abbcc9;
    cursor: pointer;
    &:active,
    &:hover {
        color: #000;
    }
    &:focus:not(:focus-visible) {
        outline: none;
    }
}

/* https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog#transitioning_dialog_elements */

.dialogAnimation[open] .dialog {
    opacity: 1;
    transform: none;
}

/*   Closed state of the dialog   */
.dialogAnimation .dialog {
    opacity: 0;
    transform: translateY(500px);
    transition: opacity 0.2s ease-out, transform 0.2s ease-out, overlay 0.2s ease-out allow-discrete,
        display 0.2s ease-out allow-discrete;

    /* Equivalent to
    transition: all 0.2s allow-discrete; */
}

/* Before-open state  */
/* Needs to be after the previous dialog[open] rule to take effect, as the specificity is the same */
@starting-style {
    .dialogAnimation[open] .dialog {
        opacity: 0;
        transform: translateY(500px);
    }
}

/* Transition the :backdrop when the dialog modal is promoted to the top layer */
.dialogAnimation::backdrop .dialog {
    background-color: rgb(0 0 0 / 0);
    transition: display 0.2s allow-discrete, overlay 0.2s allow-discrete, background-color 0.2s;

    /* Equivalent to
    transition: all 0.2s allow-discrete; */
}

.dialogAnimation[open]::backdrop .dialog {
    background-color: rgb(0 0 0 / 0.7);
}

/* This starting-style rule cannot be nested inside the above selector because the nesting selector cannot represent pseudo-elements. */
@starting-style {
    .dialogAnimation[open]::backdrop .dialog {
        background-color: rgb(0 0 0 / 0);
    }
}
