.withShadow .scrollShadow {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    overflow: hidden;
    pointer-events: none;
    &::before {
        content: '';
        display: block;
        background: #fff;
        filter: drop-shadow(0 9px 5px rgba(0, 0, 0, 0.1));
        height: 30px;
        margin-top: -30px;
        z-index: -1;
        width: calc(100% + 10px);
        margin-left: -5px;
    }
}

.planHeader {
    min-height: 100%;
    top: 0;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px 5px 20px;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    margin-left: -0.5px;
    margin-right: -0.5px;
    position: relative;
    background: inherit;
}

.planName {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.44;
    min-height: calc(2em * 1.44);
    display: flex;
    align-items: center;
    justify-content: center;
}
.price {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin: 0.3em 0;
    font-size: 18px;
    &[data-currency='PLN'] {
        align-items: flex-end;
    }
    .currency {
        line-height: 1.4;
    }
    .period {
        line-height: 2.2;
    }
    .decimal {
        font-size: 32%;
        vertical-align: text-top;
        line-height: 2.2;
    }
    &:has(.decimal) .period {
        margin-left: 0;
    }
}
.sup {
    font-size: 22px;
    margin-bottom: -0.1em;
}
.currency-text {
    font-size: 20px;
    font-weight: 700;
    margin-left: 0.2em;
    line-height: 1.5;
}
.period {
    font-size: 12px;
    line-height: 1.5;
    color: #000;
    font-weight: 400;
    margin-left: 0.1em;
}
.value {
    font-size: 28px;
    line-height: 1.286;
    letter-spacing: 0.56px;
    margin: 0;
}
.customPricing {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5em 0 0.1em;

    /* min-height -> size of formatted price */
    min-height: calc(28px * 1.286);
}
.billingInfo {
    color: #717171;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.02em;
}
.cta {
    margin-top: 1em;
}
.callToAction {
    &:hover,
    &:active,
    &:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
    }
}
