.testimonials {
    text-align: center;
    padding: 40px 0;
}
.boxes {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    max-width: 560px;
    margin: 0 auto 40px;
    @media screen and (min-width: 980px) {
        max-width: none;
        grid-template-columns: 1fr 1fr;
    }
}
.card {
    text-align: left;
    background: #fff;
    min-height: 224px;
    border-radius: 12px;
    box-shadow: 6px 6px 50px 0 rgba(0, 0, 0, 0.08);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    @media screen and (min-width: 600px) {
        grid-gap: 24px;
        grid-template-columns: minmax(218px, auto) 2.9fr;
        grid-template-rows: 1fr;
    }
}
.texts {
    padding: 26px 30px 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (min-width: 600px) {
        padding-left: 0;
    }
    p {
        margin: 0 0 10px;
        color: #000;
        font-family: var(--rubik);
        font-weight: 400;
        font-size: 18px;
        line-height: 1.55;
    }
}
.image {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-end;
    order: 2;
    @media screen and (min-width: 600px) {
        order: initial;
    }
    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: -13px auto 0 0;
        border-bottom-left-radius: 12px;
    }
}
.bottom {
    color: #000;
    font-family: var(--rubik);
    line-height: 1.6;
    span {
        display: block;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.02em;
    }
    small {
        font-size: 14px;
        font-weight: 400;
    }
}
