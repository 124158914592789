.searchForm {
    padding: 20px;
    text-align: right;
}
.searchInput {
    height: 36px;
    width: 100%;
    max-width: 210px;
    padding: 13px 20px 13px 16px;
    align-items: center;
    gap: 26px;
    flex-shrink: 0;
    align-self: stretch;
    border: none;
    border-radius: 12px;
    background: #fff url('./search.svg');
    background-repeat: no-repeat;
    background-position: top 50% right 20px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.06);
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
    &::placeholder {
        color: #717171;
    }
}
