/* stylelint-disable at-rule-no-unknown */
.dialog {
    background: #f5f7fa;
    border: none;
    border-radius: 12px;
    box-shadow: 6px 6px 50px 0 rgba(0, 0, 0, 0.08);
    padding: 20px 20px 0 0;
}
.form {
    text-align: end;
}
.close {
    display: inline-flex;
    background: none;
    border: none;
    color: #abbcc9;
    cursor: pointer;

    &:active,
    &:hover,
    &:focus {
        color: #000;
    }

    &:focus:not(:focus-visible) {
        outline: none;
    }
}
.tableWrapper {
    font-family: var(--rubik);
    max-width: 1180px;
    margin: auto;
    height: calc(100vh - 160px);
    background: #f5f7fa;
    overflow: auto;
    overscroll-behavior: contain;
}
.mainHeader {
    color: #000;
    text-align: center;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    margin-top: 0;
}

/* https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog#transitioning_dialog_elements */

.dialogAnimation[open] {
    opacity: 1;
    transform: none;
}

/*   Closed state of the dialog   */
.dialogAnimation {
    opacity: 0;
    transform: translateY(500px);
    transition: opacity 0.2s ease-out, transform 0.2s ease-out, overlay 0.2s ease-out allow-discrete,
        display 0.2s ease-out allow-discrete;

    /* Equivalent to
    transition: all 0.2s allow-discrete; */
}

/* Before-open state  */
/* Needs to be after the previous dialog[open] rule to take effect, as the specificity is the same */
@starting-style {
    .dialogAnimation[open] {
        opacity: 0;
        transform: translateY(500px);
    }
}

/* Transition the :backdrop when the dialog modal is promoted to the top layer */
.dialogAnimation::backdrop {
    background-color: rgb(0 0 0 / 0);
    transition: display 0.2s allow-discrete, overlay 0.2s allow-discrete, background-color 0.2s;

    /* Equivalent to
    transition: all 0.2s allow-discrete; */
}

.dialogAnimation[open]::backdrop {
    background-color: rgb(0 0 0 / 0.1);
}

/* This starting-style rule cannot be nested inside the above selector because the nesting selector cannot represent pseudo-elements. */
@starting-style {
    .dialogAnimation[open]::backdrop {
        background-color: rgb(0 0 0 / 0);
    }
}
