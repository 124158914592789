.scrollShadow {
    opacity: var(--scrollShadowOpacity, 1);
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    overflow: hidden;
    pointer-events: none;
    &::before {
        content: '';
        display: block;
        background: #fff;
        filter: drop-shadow(0 9px 5px rgba(0, 0, 0, 0.1));
        height: 30px;
        margin-top: -30px;
        z-index: -1;
        width: calc(100% + 10px);
        margin-left: -5px;
    }
    animation-name: scrollShadowToggle;
    animation-duration: 1ms;
    animation-direction: alternate;
    animation-fill-mode: none;
    /* stylelint-disable-next-line property-no-unknown */
    animation-timeline: scroll(block nearest);
}
@keyframes scrollShadowToggle {
    0% {
        --scrollShadowOpacity: 0;
    }
    2%,
    100% {
        --scrollShadowOpacity: 1;
    }
}

.thead th,
.thead td {
    position: sticky;
    top: 9px;
    text-align: center;
    background: #fff;
    box-sizing: border-box;
    z-index: 2;
    vertical-align: top;
    padding: 0;
}
.thead td {
    background: #f5f7fa;
    vertical-align: bottom;

    &::before {
        content: '';
        width: 1px;
        display: block;
        height: 100%;
        top: 0;
        position: absolute;
        left: -1px;
        background: #f5f7fa;
    }
}

.thead:not(.headerPresentation) th {
    border-top: none;
    border-bottom: none;
}

.headerPresentation {
    td,
    th {
        background-color: #f5f7fa;
        position: sticky;
        z-index: 1;
        top: 0;
        padding: 0;
    }
    td::before,
    th::before {
        content: '';
        position: absolute;
        display: block;
        height: 30px;
        bottom: 0;
        top: auto;
        background-color: #f5f7fa;
        width: calc(100% + 1px);
        margin-left: -0.5px;
    }
    th::after {
        content: '';
        position: relative;
        display: block;
        height: 9px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 12px 12px 0 0;
        border-bottom: none;
        width: calc(100% + 1px);
        margin-left: -0.5px;
    }
}
