.ComparisonTablePlan_withShadow__SBZNs .ComparisonTablePlan_scrollShadow__kFaLC {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    overflow: hidden;
    pointer-events: none;
}
    .ComparisonTablePlan_withShadow__SBZNs .ComparisonTablePlan_scrollShadow__kFaLC::before {
        content: '';
        display: block;
        background: #fff;
        filter: drop-shadow(0 9px 5px rgba(0, 0, 0, 0.1));
        height: 30px;
        margin-top: -30px;
        z-index: -1;
        width: calc(100% + 10px);
        margin-left: -5px;
    }

.ComparisonTablePlan_planHeader__vwH3O {
    min-height: 100%;
    top: 0;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px 5px 20px;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    margin-left: -0.5px;
    margin-right: -0.5px;
    position: relative;
    background: inherit;
}

.ComparisonTablePlan_planName__fY_N8 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.44;
    min-height: calc(2em * 1.44);
    display: flex;
    align-items: center;
    justify-content: center;
}
.ComparisonTablePlan_price__b5Vl2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin: 0.3em 0;
    font-size: 18px;
}
.ComparisonTablePlan_price__b5Vl2[data-currency='PLN'] {
        align-items: flex-end;
    }
.ComparisonTablePlan_price__b5Vl2 .ComparisonTablePlan_currency__NXIEg {
        line-height: 1.4;
    }
.ComparisonTablePlan_price__b5Vl2 .ComparisonTablePlan_period__gZ2Rw {
        line-height: 2.2;
    }
.ComparisonTablePlan_price__b5Vl2 .ComparisonTablePlan_decimal__p8TAh {
        font-size: 32%;
        vertical-align: text-top;
        line-height: 2.2;
    }
.ComparisonTablePlan_price__b5Vl2:has(.ComparisonTablePlan_decimal__p8TAh) .ComparisonTablePlan_period__gZ2Rw {
        margin-left: 0;
    }
.ComparisonTablePlan_sup__CbLjk {
    font-size: 22px;
    margin-bottom: -0.1em;
}
.ComparisonTablePlan_currency-text__wBS4K {
    font-size: 20px;
    font-weight: 700;
    margin-left: 0.2em;
    line-height: 1.5;
}
.ComparisonTablePlan_period__gZ2Rw {
    font-size: 12px;
    line-height: 1.5;
    color: #000;
    font-weight: 400;
    margin-left: 0.1em;
}
.ComparisonTablePlan_value__zGyFG {
    font-size: 28px;
    line-height: 1.286;
    letter-spacing: 0.56px;
    margin: 0;
}
.ComparisonTablePlan_customPricing__dujQz {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5em 0 0.1em;

    /* min-height -> size of formatted price */
    min-height: calc(28px * 1.286);
}
.ComparisonTablePlan_billingInfo__W6vX2 {
    color: #717171;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.02em;
}
.ComparisonTablePlan_cta__NjdKo {
    margin-top: 1em;
}
.ComparisonTablePlan_callToAction__Kh9A_:hover,
    .ComparisonTablePlan_callToAction__Kh9A_:active,
    .ComparisonTablePlan_callToAction__Kh9A_:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
    }

.ComparisonTablePlansHeader_scrollShadow__5EOjG {
    opacity: var(--scrollShadowOpacity, 1);
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    overflow: hidden;
    pointer-events: none;
    /* stylelint-disable-next-line property-no-unknown */
}
    .ComparisonTablePlansHeader_scrollShadow__5EOjG::before {
        content: '';
        display: block;
        background: #fff;
        filter: drop-shadow(0 9px 5px rgba(0, 0, 0, 0.1));
        height: 30px;
        margin-top: -30px;
        z-index: -1;
        width: calc(100% + 10px);
        margin-left: -5px;
    }
    .ComparisonTablePlansHeader_scrollShadow__5EOjG {
    animation-name: ComparisonTablePlansHeader_scrollShadowToggle__7COVr;
    animation-duration: 1ms;
    animation-direction: alternate;
    animation-fill-mode: none;
    animation-timeline: scroll(block nearest);
}
@keyframes ComparisonTablePlansHeader_scrollShadowToggle__7COVr {
    0% {
        --scrollShadowOpacity: 0;
    }
    2%,
    100% {
        --scrollShadowOpacity: 1;
    }
}

.ComparisonTablePlansHeader_thead__dQxn_ th,
.ComparisonTablePlansHeader_thead__dQxn_ td {
    position: sticky;
    top: 9px;
    text-align: center;
    background: #fff;
    box-sizing: border-box;
    z-index: 2;
    vertical-align: top;
    padding: 0;
}
.ComparisonTablePlansHeader_thead__dQxn_ td {
    background: #f5f7fa;
    vertical-align: bottom;
}
.ComparisonTablePlansHeader_thead__dQxn_ td::before {
        content: '';
        width: 1px;
        display: block;
        height: 100%;
        top: 0;
        position: absolute;
        left: -1px;
        background: #f5f7fa;
    }

.ComparisonTablePlansHeader_thead__dQxn_:not(.ComparisonTablePlansHeader_headerPresentation__KQXW6) th {
    border-top: none;
    border-bottom: none;
}

.ComparisonTablePlansHeader_headerPresentation__KQXW6 td,
    .ComparisonTablePlansHeader_headerPresentation__KQXW6 th {
        background-color: #f5f7fa;
        position: sticky;
        z-index: 1;
        top: 0;
        padding: 0;
    }

.ComparisonTablePlansHeader_headerPresentation__KQXW6 td::before,
    .ComparisonTablePlansHeader_headerPresentation__KQXW6 th::before {
        content: '';
        position: absolute;
        display: block;
        height: 30px;
        bottom: 0;
        top: auto;
        background-color: #f5f7fa;
        width: calc(100% + 1px);
        margin-left: -0.5px;
    }

.ComparisonTablePlansHeader_headerPresentation__KQXW6 th::after {
        content: '';
        position: relative;
        display: block;
        height: 9px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 12px 12px 0 0;
        border-bottom: none;
        width: calc(100% + 1px);
        margin-left: -0.5px;
    }

.ComparisonTableSearch_searchForm__SnmFj {
    padding: 20px;
    text-align: right;
}
.ComparisonTableSearch_searchInput__hme3R {
    height: 36px;
    width: 100%;
    max-width: 210px;
    padding: 13px 20px 13px 16px;
    align-items: center;
    gap: 26px;
    flex-shrink: 0;
    align-self: stretch;
    border: none;
    border-radius: 12px;
    background: #fff url(https://us-wn-g.gr-cdn.com/_next/static/media/search.c5f1cbcf.svg);
    background-repeat: no-repeat;
    background-position: top 50% right 20px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.06);
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.ComparisonTableSearch_searchInput__hme3R::-webkit-search-decoration,
    .ComparisonTableSearch_searchInput__hme3R::-webkit-search-cancel-button,
    .ComparisonTableSearch_searchInput__hme3R::-webkit-search-results-button,
    .ComparisonTableSearch_searchInput__hme3R::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
.ComparisonTableSearch_searchInput__hme3R::-moz-placeholder {
        color: #717171;
    }
.ComparisonTableSearch_searchInput__hme3R::placeholder {
        color: #717171;
    }

/* stylelint-disable at-rule-no-unknown */
.ComparisonTableDialog_dialog__kVb52 {
    background: #f5f7fa;
    border: none;
    border-radius: 12px;
    box-shadow: 6px 6px 50px 0 rgba(0, 0, 0, 0.08);
    padding: 20px 20px 0 0;
}
.ComparisonTableDialog_form__SPgtm {
    text-align: end;
}
.ComparisonTableDialog_close__s8Oui {
    display: inline-flex;
    background: none;
    border: none;
    color: #abbcc9;
    cursor: pointer;
}
.ComparisonTableDialog_close__s8Oui:active,
    .ComparisonTableDialog_close__s8Oui:hover,
    .ComparisonTableDialog_close__s8Oui:focus {
        color: #000;
    }
.ComparisonTableDialog_close__s8Oui:focus:not(:focus-visible) {
        outline: none;
    }
.ComparisonTableDialog_tableWrapper__AMMLy {
    font-family: var(--rubik);
    max-width: 1180px;
    margin: auto;
    height: calc(100vh - 160px);
    background: #f5f7fa;
    overflow: auto;
    overscroll-behavior: contain;
}
.ComparisonTableDialog_mainHeader__A0mOs {
    color: #000;
    text-align: center;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    margin-top: 0;
}

/* https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog#transitioning_dialog_elements */

.ComparisonTableDialog_dialogAnimation__SqeXE[open] {
    opacity: 1;
    transform: none;
}

/*   Closed state of the dialog   */
.ComparisonTableDialog_dialogAnimation__SqeXE {
    opacity: 0;
    transform: translateY(500px);
    transition: opacity 0.2s ease-out, transform 0.2s ease-out, overlay 0.2s ease-out allow-discrete,
        display 0.2s ease-out allow-discrete;

    /* Equivalent to
    transition: all 0.2s allow-discrete; */
}

/* Before-open state  */
/* Needs to be after the previous dialog[open] rule to take effect, as the specificity is the same */
@starting-style {
    .ComparisonTableDialog_dialogAnimation__SqeXE[open] {
        opacity: 0;
        transform: translateY(500px);
    }
}

/* Transition the :backdrop when the dialog modal is promoted to the top layer */
.ComparisonTableDialog_dialogAnimation__SqeXE::backdrop {
    background-color: rgb(0 0 0 / 0);
    transition: display 0.2s allow-discrete, overlay 0.2s allow-discrete, background-color 0.2s;

    /* Equivalent to
    transition: all 0.2s allow-discrete; */
}

.ComparisonTableDialog_dialogAnimation__SqeXE[open]::backdrop {
    background-color: rgb(0 0 0 / 0.1);
}

/* This starting-style rule cannot be nested inside the above selector because the nesting selector cannot represent pseudo-elements. */
@starting-style {
    .ComparisonTableDialog_dialogAnimation__SqeXE[open]::backdrop {
        background-color: rgb(0 0 0 / 0);
    }
}

.Faq_faq__xLr0H {
    padding: 40px 0 100px;
}
    .Faq_faq__xLr0H h2 {
        text-align: center;
    }
    .Faq_faq__xLr0H header {
        margin-bottom: 40px;
    }
.Faq_questions__522Nc {
    max-width: 860px;
    margin: 0 auto;
}

.Header_header__I77Io {
    box-sizing: border-box;
    margin: 0 0 60px;
}
    .Header_header__I77Io h1,
    .Header_header__I77Io h2 {
        color: #000;
        font-family: var(--rubik);
        font-weight: 600;
        margin: 0 0 30px;
        padding: 0;
        line-height: 1.3;
    }
    @media only screen and (min-width: 768px) {
    .Header_header__I77Io h1,
    .Header_header__I77Io h2 {
            line-height: 1.35
    }
        }
    .Header_header__I77Io h1 {
        font-size: 28px;
    }
    @media only screen and (min-width: 768px) {
    .Header_header__I77Io h1 {
            font-size: 54px
    }
        }
    .Header_header__I77Io h2 {
        font-size: 28px;
    }
    @media only screen and (min-width: 768px) {
    .Header_header__I77Io h2 {
            font-size: 34px
    }
        }
    .Header_header__I77Io h2 span {
            color: var(--primary-brand-color-600);
        }
    .Header_header__I77Io p {
        color: #000;
        text-align: center;
        font-family: var(--rubik);
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        margin: 0 0 20px;
        padding: 0;
    }
    .Header_header__I77Io p b,
        .Header_header__I77Io p strong {
            font-weight: 700;
        }

.Testimonials_testimonials__qCNym {
    text-align: center;
    padding: 40px 0;
}
.Testimonials_boxes__mALsW {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    max-width: 560px;
    margin: 0 auto 40px;
}
@media screen and (min-width: 980px) {
.Testimonials_boxes__mALsW {
        max-width: none;
        grid-template-columns: 1fr 1fr
}
    }
.Testimonials_card__bJxrT {
    text-align: left;
    background: #fff;
    min-height: 224px;
    border-radius: 12px;
    box-shadow: 6px 6px 50px 0 rgba(0, 0, 0, 0.08);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
}
@media screen and (min-width: 600px) {
.Testimonials_card__bJxrT {
        grid-gap: 24px;
        grid-template-columns: minmax(218px, auto) 2.9fr;
        grid-template-rows: 1fr
}
    }
.Testimonials_texts__6UkPx {
    padding: 26px 30px 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
@media screen and (min-width: 600px) {
.Testimonials_texts__6UkPx {
        padding-left: 0
}
    }
.Testimonials_texts__6UkPx p {
        margin: 0 0 10px;
        color: #000;
        font-family: var(--rubik);
        font-weight: 400;
        font-size: 18px;
        line-height: 1.55;
    }
.Testimonials_image__KX9DH {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-end;
    order: 2;
}
@media screen and (min-width: 600px) {
.Testimonials_image__KX9DH {
        order: initial
}
    }
.Testimonials_image__KX9DH img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: -13px auto 0 0;
        border-bottom-left-radius: 12px;
    }
.Testimonials_bottom__Fg1VH {
    color: #000;
    font-family: var(--rubik);
    line-height: 1.6;
}
.Testimonials_bottom__Fg1VH span {
        display: block;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.02em;
    }
.Testimonials_bottom__Fg1VH small {
        font-size: 14px;
        font-weight: 400;
    }

.Brands_brands__T7dQ1 {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 0;
    margin: 0;
}
    @media screen and (min-width: 980px) {.Brands_brands__T7dQ1 {
        justify-content: space-between
}
    }
    .Brands_brands__T7dQ1 li {
        margin: 20px;
        display: flex;
        align-items: center;
    }
    @media screen and (min-width: 980px) {
    .Brands_brands__T7dQ1 li {
            margin: 0
    }
        }
    .Brands_brands__T7dQ1 figure {
        margin: 0;
    }
    .Brands_brands__T7dQ1 figure img {
            vertical-align: bottom;
        }

.Button_btn__5vn34 {
    --arrow-height: calc(1em * 0.8);
    --arrow-width: calc(1em * 15 / 13 * 0.8);
    font-family: var(--rubik);
    display: inline-block;
    box-sizing: border-box;
    border: 2px solid #e3d500;
    border-radius: 16px;
    padding: 14px 20px;
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    background: #fbe30c;
    color: #191b19;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    cursor: pointer;
}
    .Button_btn__5vn34.Button_arrow__t4LpR > span {
        padding-right: calc(0.3em + var(--arrow-width));
    }
    .Button_btn__5vn34:focus:not(:focus-visible) {
        outline: none;
    }
    .Button_btn__5vn34.Button_arrow__t4LpR::after {
        content: '';
        display: inline-block;
        width: var(--arrow-width);
        height: var(--arrow-height);
        margin-left: calc(-1 * var(--arrow-width));
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.581" viewBox="0 0 24 20.581"><path id="arrow-right" d="M23.5,75.426A1.717,1.717,0,0,0,23.5,73l-8.572-8.572A1.716,1.716,0,0,0,12.5,66.854l3.106,3.1L18.151,72.5H1.714a1.714,1.714,0,0,0,0,3.429H18.146L12.5,81.576A1.716,1.716,0,0,0,14.931,84L23.5,75.431Z" transform="translate(0 -63.925)" fill="%23191b19"/></svg>');
        vertical-align: middle;
        transform: translateY(-10%);
    }
    .Button_btn__5vn34:hover,
    .Button_btn__5vn34:active,
    .Button_btn__5vn34:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
        color: #fff;
    }
    .Button_btn__5vn34:hover.Button_arrow__t4LpR::after, .Button_btn__5vn34:active.Button_arrow__t4LpR::after, .Button_btn__5vn34:focus.Button_arrow__t4LpR::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.581" viewBox="0 0 24 20.581"><path id="arrow-right" d="M23.5,75.426A1.717,1.717,0,0,0,23.5,73l-8.572-8.572A1.716,1.716,0,0,0,12.5,66.854l3.106,3.1L18.151,72.5H1.714a1.714,1.714,0,0,0,0,3.429H18.146L12.5,81.576A1.716,1.716,0,0,0,14.931,84L23.5,75.431Z" transform="translate(0 -63.925)" fill="%23ffffff"/></svg>');
        }
.Button_large__LaZYG {
    padding: 17px 31px;
    border-radius: 24px;
    font-size: 18px;
    border-width: 3px;
}

.Button_small__UR5o6 {
    padding: 12px 14px;
    font-size: 14px;
    border-radius: 12px;
    border-width: 2px;
}

.Button_black__vmqnX {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.Button_black__vmqnX.Button_arrow__t4LpR::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_black__vmqnX:hover,
    .Button_black__vmqnX:active,
    .Button_black__vmqnX:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
        color: #fff;
    }

.Button_red__wh_2R {
    border-color: #002c71;
    background: #f04;
    color: #fff;
}

.Button_red__wh_2R.Button_arrow__t4LpR::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_red__wh_2R:hover {
        background: #fff;
        color: #0b3685;
    }

.Button_red__wh_2R:hover.Button_arrow__t4LpR::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%230B3685"/></svg>');
        }

.Button_orange__hnCIa {
    background: #fd733c;
    border-color: #e2612b;
    color: #fff;
}

.Button_orange__hnCIa.Button_arrow__t4LpR::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_orange__hnCIa:hover {
        border-color: #de4d14;
        background-color: #ff5b1a;
    }

.Button_max-orange__6rifX {
    background: var(--primary-max-brand-color-300, #fc5b1a);
    border-color: var(--primary-max-brand-color-400, #de4d14);
    color: #fff;
}

.Button_max-orange__6rifX.Button_arrow__t4LpR::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_max-orange__6rifX:hover,
    .Button_max-orange__6rifX:active,
    .Button_max-orange__6rifX:focus {
        border-color: var(--primary-max-brand-color-400, #de4d14);
        background-color: var(--primary-max-brand-color-400, #de4d14);
    }

.Button_max-black__ZKSjr {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.Button_max-black__ZKSjr.Button_arrow__t4LpR::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_max-black__ZKSjr:hover,
    .Button_max-black__ZKSjr:active,
    .Button_max-black__ZKSjr:focus {
        border-color: var(--primary-max-brand-color-400, #de4d14);
        background-color: var(--primary-max-brand-color-400, #de4d14);
        color: #fff;
    }

.Button_black-white__ruHE9 {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.Button_black-white__ruHE9.Button_arrow__t4LpR {
        text-wrap: nowrap;
    }

.Button_black-white__ruHE9.Button_arrow__t4LpR::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
        }

.Button_black-white__ruHE9:hover,
    .Button_black-white__ruHE9:active,
    .Button_black-white__ruHE9:focus {
        border-color: #000;
        background-color: #fff;
        color: #000;
    }

.Button_black-white__ruHE9:hover.Button_arrow__t4LpR::after, .Button_black-white__ruHE9:active.Button_arrow__t4LpR::after, .Button_black-white__ruHE9:focus.Button_arrow__t4LpR::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23000"/></svg>');
        }

.Button_blue__bv9Ro {
    background-color: #38a2ff;
    color: #fff;
    border-color: #3393f2;
}

.Button_blue__bv9Ro.Button_arrow__t4LpR::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_blue__bv9Ro:hover,
    .Button_blue__bv9Ro:active,
    .Button_blue__bv9Ro:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
        color: #fff;
    }

.Button_ghost__pcGB2 {
    border-color: #abbcc9;
    background: transparent;
    color: #000;
}

.Button_ghost__pcGB2:hover,
    .Button_ghost__pcGB2:active,
    .Button_ghost__pcGB2:focus {
        background-color: #000;
        border-color: #000;
        color: #fff;
    }

.Button_ghost-blue__Kx4XK {
    border-color: #19a1ff;
    background: transparent;
    color: #fff;
    transition: all 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.Button_ghost-blue__Kx4XK.Button_arrow__t4LpR::after {
        transition: all 0.3s ease-in-out, border-color 0.3s ease-in-out;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_ghost-blue__Kx4XK:hover,
    .Button_ghost-blue__Kx4XK:active,
    .Button_ghost-blue__Kx4XK:focus {
        background-color: transparent;
        border-color: var(--primary-max-brand-color-300, #fc5b1a);
        color: var(--primary-max-brand-color-300, #fc5b1a);
    }

.Button_ghost-blue__Kx4XK:hover.Button_arrow__t4LpR::after, .Button_ghost-blue__Kx4XK:active.Button_arrow__t4LpR::after, .Button_ghost-blue__Kx4XK:focus.Button_arrow__t4LpR::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fc5b1a"/></svg>');
        }

.Button_disabled__Puphs {
    border-color: #d3d5d9;
    background: #f5f7fa;
    color: #abbcc9;
}

.Button_disabled__Puphs.Button_arrow__t4LpR::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23abbcc9"/></svg>');
    }

.Button_disabled__Puphs:hover,
    .Button_disabled__Puphs:active,
    .Button_disabled__Puphs:focus {
        border-color: #d3d5d9;
        background: #f5f7fa;
        color: #abbcc9;
    }

/* stylelint-disable at-rule-no-unknown */
.ExitPopup_dialogContainer__pI0mF[open] {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    margin: 0;
    padding: 0;
    border: 0;
    pointer-events: all;
}
.ExitPopup_dialog__v7_va {
    background: #fff url(https://us-wn-g.gr-cdn.com/_next/static/media/bg.df238e7f.svg) left center no-repeat;
    background-size: 560px 219px;
    box-shadow: 20px 20px 90px 0 rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    width: 100%;
    max-width: 600px;
    min-height: 292px;
    margin: 20px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    display: flex;
}
.ExitPopup_inner__KsVtu {
    align-self: center;
    width: 100%;
    padding: 30px 20px;
}
.ExitPopup_inner__KsVtu h2 {
        color: #000;
        position: relative;
        z-index: 1;
        font-size: 30px;
        font-weight: 600;
        line-height: 1.35;
        font-family: var(--rubik);
        margin: 0 0 24px;
        --underline-offset: 0.1em;
    }
.ExitPopup_inner__KsVtu h2 u {
            color: var(--primary-brand-color-600);
        }
@media screen and (min-width: 768px) {
.ExitPopup_inner__KsVtu h2 {
            font-size: 34px
    }
        }
.ExitPopup_inner__KsVtu p {
        color: #000;
        font-family: var(--rubik);
        font-size: 16px;
        font-weight: 700;
        line-height: 1.2;
        margin: 0 0 17px;
    }
.ExitPopup_close__u7i_9 {
    position: absolute;
    padding: 10px;
    top: 0;
    right: 0;
    display: inline-flex;
    background: none;
    border: none;
    color: #abbcc9;
    cursor: pointer;
}
.ExitPopup_close__u7i_9:active,
    .ExitPopup_close__u7i_9:hover {
        color: #000;
    }
.ExitPopup_close__u7i_9:focus:not(:focus-visible) {
        outline: none;
    }

/* https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog#transitioning_dialog_elements */

.ExitPopup_dialogAnimation__jy4YT[open] .ExitPopup_dialog__v7_va {
    opacity: 1;
    transform: none;
}

/*   Closed state of the dialog   */
.ExitPopup_dialogAnimation__jy4YT .ExitPopup_dialog__v7_va {
    opacity: 0;
    transform: translateY(500px);
    transition: opacity 0.2s ease-out, transform 0.2s ease-out, overlay 0.2s ease-out allow-discrete,
        display 0.2s ease-out allow-discrete;

    /* Equivalent to
    transition: all 0.2s allow-discrete; */
}

/* Before-open state  */
/* Needs to be after the previous dialog[open] rule to take effect, as the specificity is the same */
@starting-style {
    .ExitPopup_dialogAnimation__jy4YT[open] .ExitPopup_dialog__v7_va {
        opacity: 0;
        transform: translateY(500px);
    }
}

/* Transition the :backdrop when the dialog modal is promoted to the top layer */
.ExitPopup_dialogAnimation__jy4YT::backdrop .ExitPopup_dialog__v7_va {
    background-color: rgb(0 0 0 / 0);
    transition: display 0.2s allow-discrete, overlay 0.2s allow-discrete, background-color 0.2s;

    /* Equivalent to
    transition: all 0.2s allow-discrete; */
}

.ExitPopup_dialogAnimation__jy4YT[open]::backdrop .ExitPopup_dialog__v7_va {
    background-color: rgb(0 0 0 / 0.7);
}

/* This starting-style rule cannot be nested inside the above selector because the nesting selector cannot represent pseudo-elements. */
@starting-style {
    .ExitPopup_dialogAnimation__jy4YT[open]::backdrop .ExitPopup_dialog__v7_va {
        background-color: rgb(0 0 0 / 0);
    }
}

.Header_header__6fcjG {
    padding: 30px 0 22px;
    text-align: center;
}
.Header_title__CQXTW {
    color: #000;
    font-family: var(--rubik);
    font-size: clamp(35px, 6vw, 54px);
    font-weight: 600;
    margin: 0 auto 15px;
    line-height: 1.2;
    letter-spacing: -0.001em;
    --underline-offset: 0.15em;
}
.Header_title__CQXTW u {
        color: var(--primary-brand-color-600);
    }
.Header_text__J4rmZ {
    margin: 0 auto 10px;
    max-width: 820px;
}
.Header_text__J4rmZ p {
        color: #000;
        line-height: 1.4;
        font-family: var(--rubik);
        font-size: 18px;
        margin: 0 0 10px;
    }

.TryFree_tryFree__UU_9v {
    padding: 40px 0;
    text-align: center;
}
    .TryFree_tryFree__UU_9v h2 {
        margin-bottom: 24px;
    }
    .TryFree_tryFree__UU_9v header {
        margin-bottom: 30px;
    }

