.faq {
    padding: 40px 0 100px;
    h2 {
        text-align: center;
    }
    header {
        margin-bottom: 40px;
    }
}
.questions {
    max-width: 860px;
    margin: 0 auto;
}
