.header {
    padding: 30px 0 22px;
    text-align: center;
}
.title {
    color: #000;
    font-family: var(--rubik);
    font-size: clamp(35px, 6vw, 54px);
    font-weight: 600;
    margin: 0 auto 15px;
    line-height: 1.2;
    letter-spacing: -0.001em;
    --underline-offset: 0.15em;
    u {
        color: var(--primary-brand-color-600);
    }
}
.text {
    margin: 0 auto 10px;
    max-width: 820px;
    p {
        color: #000;
        line-height: 1.4;
        font-family: var(--rubik);
        font-size: 18px;
        margin: 0 0 10px;
    }
}
