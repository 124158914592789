.brands {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 0;
    margin: 0;
    @media screen and (min-width: 980px) {
        justify-content: space-between;
    }
    li {
        margin: 20px;
        display: flex;
        align-items: center;
        @media screen and (min-width: 980px) {
            margin: 0;
        }
    }
    figure {
        margin: 0;
        img {
            vertical-align: bottom;
        }
    }
}
